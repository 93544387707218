import { CLAIM_COMPONENT_ADDRESS, CLAIM_NFT_ADDRESS, HUG_RESOURCE_ADDRESS, STAKING_COMPONENT_ADDRESS, TIP_OWNER_BADGE, XRD_RESOURCE_ADDRESS, sHUG_RESOURCE_ADDRESS } from "./util";

export function generateClaimManifest(account: string, localId: string, claims: []) {
	let manifest = `

`;

	for (let claim of claims) {

		// Skip empty claims for TX cosst and royalties
		if (claim.remaining == 0) {
			continue;
		}

		manifest += `
CALL_METHOD
    Address("${account}")
    "create_proof_of_non_fungibles"
    Address("${CLAIM_NFT_ADDRESS}")
    Array<NonFungibleLocalId>(
        NonFungibleLocalId("${localId}")
    )
;

POP_FROM_AUTH_ZONE
    Proof("proof${claim.type}")
;

CALL_METHOD
    Address("${CLAIM_COMPONENT_ADDRESS}")
    "receive_hugs"
    ${claim.type}u8
    Decimal("${claim.remaining}")
	Proof("proof${claim.type}")
;`;
	}

	manifest += `
CALL_METHOD
    Address("${account}")
    "deposit_batch"
    Expression("ENTIRE_WORKTOP")
;
`

	return manifest;
}

export function generateClaimAndStakeManifest(account: string, localId: string, claims: []) {
	let manifest = `

`;

	for (let claim of claims) {

		// Skip empty claims for TX cosst and royalties
		if (claim.remaining == 0) {
			continue;
		}

		manifest += `
CALL_METHOD
    Address("${account}")
    "create_proof_of_non_fungibles"
    Address("${CLAIM_NFT_ADDRESS}")
    Array<NonFungibleLocalId>(
        NonFungibleLocalId("${localId}")
    )
;

POP_FROM_AUTH_ZONE
    Proof("proof${claim.type}")
;

CALL_METHOD
    Address("${CLAIM_COMPONENT_ADDRESS}")
    "receive_hugs"
    ${claim.type}u8
    Decimal("${claim.remaining}")
	Proof("proof${claim.type}")
;`;
	}

	manifest += `
TAKE_ALL_FROM_WORKTOP
	Address("${HUG_RESOURCE_ADDRESS}")
	Bucket("stake_bucket")
;

CALL_METHOD
	Address("${STAKING_COMPONENT_ADDRESS}")
	"add_stake"
	Bucket("stake_bucket")
;

CALL_METHOD
    Address("${account}")
    "deposit_batch"
    Expression("ENTIRE_WORKTOP")
;
`

	return manifest;
}

export function generateStakingManifest(account: string, amount: string) {
	return `
CALL_METHOD
	Address("${account}")
	"withdraw"
	Address("${HUG_RESOURCE_ADDRESS}")
	Decimal("${amount}")
;

TAKE_FROM_WORKTOP
	Address("${HUG_RESOURCE_ADDRESS}")
	Decimal("${amount}")
	Bucket("astrl_bucket")
;

CALL_METHOD
	Address("${STAKING_COMPONENT_ADDRESS}")
	"add_stake"
	Bucket("astrl_bucket")
;

CALL_METHOD 
	Address("${account}") 
	"deposit_batch" 
	Expression("ENTIRE_WORKTOP")
;`;
}

export function generateUnstakingManifest(account: string, amount: string) {
	return `
CALL_METHOD
	Address("${account}")
	"withdraw"
	Address("${sHUG_RESOURCE_ADDRESS}")
	Decimal("${amount}")
;

TAKE_FROM_WORKTOP
	Address("${sHUG_RESOURCE_ADDRESS}")
	Decimal("${amount}")
	Bucket("astrl_bucket")
;

CALL_METHOD
	Address("${STAKING_COMPONENT_ADDRESS}")
	"remove_stake"
	Bucket("astrl_bucket")
;

CALL_METHOD 
	Address("${account}") 
	"deposit_batch" 
	Expression("ENTIRE_WORKTOP")
;`;
}

export function generateRegisterManifest(account: string, localId: string) {
	const amount = 0.5;

	return `
CALL_METHOD
	Address("${account}")
	"withdraw"
	Address("${XRD_RESOURCE_ADDRESS}")
	Decimal("${amount}")
;

TAKE_FROM_WORKTOP
	Address("${XRD_RESOURCE_ADDRESS}")
	Decimal("${amount}")
	Bucket("tokens")
;

ALLOCATE_GLOBAL_ADDRESS
    Address("package_rdx1pkgxxxxxxxxxresrcexxxxxxxxx000538436477xxxxxxxxxresrce")
    "NonFungibleResourceManager"
    AddressReservation("nft_address_reservation")
    NamedAddress("nft_address")
;

CREATE_NON_FUNGIBLE_RESOURCE_WITH_INITIAL_SUPPLY
    Enum<0u8>()
    Enum<1u8>()
    true
    Enum<0u8>(
        Enum<0u8>(
            Tuple(
                Array<Enum>(),
                Array<Tuple>(),
                Array<Enum>()
            )
        ),
        Enum<0u8>(
            66u8
        ),
        Array<String>()
    )
    Map<NonFungibleLocalId, Tuple>(
        NonFungibleLocalId("#1#") => Tuple(
            Tuple()
        )
    )
    Tuple(
        Enum<0u8>(),
        Enum<0u8>(),
        Enum<0u8>(),
        Enum<0u8>(),
        Enum<0u8>(),
        Enum<0u8>(),
        Enum<0u8>()
    )
    Tuple(
        Map<String, Tuple>(
            "name" => Tuple(
                Enum<1u8>(
                    Enum<0u8>(
                        "HUG - Tip Accounts Badge"
                    )
                ),
                false
            ),
            "description" => Tuple(
                Enum<1u8>(
                    Enum<0u8>(
                        "The owner badge for your HUG tip account"
                    )
                ),
                false
            ),
            "tags" => Tuple(
                Enum<1u8>(
                    Enum<128u8>(
                        Array<String>(
                            "badge"
                        )
                    )
                ),
                false
            ),
            "info_url" => Tuple(
                Enum<1u8>(
                    Enum<13u8>(
                        "https://receive.hug.meme/"
                    )
                ),
                false
            ),
            "icon_url" => Tuple(
                Enum<1u8>(
                    Enum<13u8>(
                        "https://hug.meme/assets/tip/vault-badge.gif"
                    )
                ),
                false
            )
        ),
        Map<String, Enum>()
    )
    Enum<1u8>(
    AddressReservation("nft_address_reservation")
  )
;

ALLOCATE_GLOBAL_ADDRESS
    Address("package_rdx1pkgxxxxxxxxxaccntxxxxxxxxxx000929625493xxxxxxxxxaccntx")
    "Account"
    AddressReservation("address_reservation")
    NamedAddress("address")
;

CREATE_ACCOUNT_ADVANCED
	Enum<2u8>(
		Enum<2u8>(
			Enum<0u8>(
					Enum<4u8>(
						Array<Enum>(
							Enum<1u8>(
								NamedAddress("nft_address")
							),
							Enum<0u8>(
								NonFungibleGlobalId("${TIP_OWNER_BADGE}")
							)
						)
					)
			)
		)
	)
	Enum<1u8>(
    AddressReservation("address_reservation")
  )
;

CALL_METHOD
	NamedAddress("address")
	"try_deposit_or_abort" 
	Bucket("tokens")
   None
;

CALL_METHOD
    Address("${account}")
    "deposit_batch"
    Expression("ENTIRE_WORKTOP")
;
	`

}

export function generateWithdrawManifest(account: string, tipAccount: string, amounts: [], badgeAddress: string, localId: string) {
	console.log(amounts);


	let manifest = `
CALL_METHOD
    Address("${account}")
    "create_proof_of_non_fungibles"
    Address("${badgeAddress}")
    Array<NonFungibleLocalId>(
        NonFungibleLocalId("${localId}")
    )
;
    `

	for (let amount of amounts) {
		manifest += `
CALL_METHOD
	Address("${tipAccount}")
	"withdraw"
	Address("${amount.address}")
	Decimal("${amount.amount}")
;
		`
	}

	return manifest + `
CALL_METHOD
    Address("${account}")
    "deposit_batch"
    Expression("ENTIRE_WORKTOP")
;`
}